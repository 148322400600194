import React, {useRef, useState} from 'react';
import {Grid, Typography, TextField, Stack, Box, Paper, IconButton, Tooltip, Alert} from '@mui/material';
import {WaitButton} from 'components';
import {Form, notEmpty, mustBeAuNumber, Select} from 'components/form';
import {useAsyncState} from 'app/hooks';
import {useAppDispatch, updateProfile, verifyPhoneCode, verifyPhone} from 'app/store';
import {getAuth, RecaptchaVerifier} from 'firebase/auth';
import EditIcon from '@mui/icons-material/Edit';
import {navigate} from "gatsby";

import type {state} from 'app';

type UserData = Pick<
  state.User,
  | 'userFirstname'
  | 'userSurname'
  | 'phoneNumber'
  | 'childName'
  | 'childPronouns'
>;

const validators = {
  userFirstname: notEmpty(),
  userSurname: notEmpty(),
  phoneNumber: mustBeAuNumber(),
  childName: notEmpty(),
  childPronouns: notEmpty('Please select one'),
};

const preferredPronounsOptions = [
  {
    label: 'He/Him/His',
    value: 'male',
  },
  {
    label: 'She/Her/Hers',
    value: 'female',
  },
  {
    label: 'They/Them/Their',
    value: 'non-binary',
  },
];

export function UserProfileForm({user, onClose}: { user: state.User; onClose: () => void }) {
  const dispatch = useAppDispatch();
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const [updateState, success, failure, waiting] = useAsyncState();
  const [isPhoneEditable, setPhoneEditable] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(currentUser?.phoneNumber || '');
  const [isVerificationPending, setVerificationPending] = useState(false);
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorSendCode, setErrorSendCode] = useState<string | null>(null);
  const [errorVerifyCode, setErrorVerifyCode] = useState<string | null>(null);
  const [verificationCode, setVerificationCode] = useState<string>('');

  let appVerifier: RecaptchaVerifier | null = null;
  const recaptchaWrapperRef = useRef<HTMLDivElement>(null); // Ref for the recaptcha container
  const [recaptchaId, setRecaptchaId] = useState<string>('recaptcha-container'); // Unique ID state
  const generateUniqueRecaptchaId = () => `recaptcha-container-${Date.now()}`;


  const generateRecaptcha = (recaptchaIdParam: string) => {
    const auth = getAuth();
    console.log('newRecaptchaId:', recaptchaIdParam);
    appVerifier = new RecaptchaVerifier(
      auth, recaptchaIdParam, // Use the passed recaptchaId
      {
        size: "invisible",
      }
    );
  };

  const recreateRecaptchaContainer = () => {
    if (recaptchaWrapperRef.current) {
      recaptchaWrapperRef.current.innerHTML = '';
    }
    const newRecaptchaId = generateUniqueRecaptchaId();
    console.log('newRecaptchaId111111111:', newRecaptchaId);
    setRecaptchaId(newRecaptchaId);
    const newContainer = document.createElement('div');
    newContainer.id = newRecaptchaId;
    if (recaptchaWrapperRef.current) {
      recaptchaWrapperRef.current.appendChild(newContainer);
    }
    return newRecaptchaId;
  };

  const handlePhoneEdit = () => {
    setPhoneEditable(true);
  };

  const handleSendVerification = async () => {
    if (phoneNumber === currentUser?.phoneNumber) {
      alert('You are trying to verify the same number. Please enter a different one.');
      return;
    }
    let formattedPhoneNumber = phoneNumber.trim();
    if (formattedPhoneNumber.startsWith('0')) {
      formattedPhoneNumber = '+61' + formattedPhoneNumber.substring(1);
    } else if (!formattedPhoneNumber.startsWith('+61')) {
      setErrorSendCode('Please enter a valid Australian phone number starting with +61 or 0.');
      return;
    }
    setErrorSendCode(null);
    setIsLoading(true);
    try {
      // Re-create the reCAPTCHA container
      const newRecaptchaId = recreateRecaptchaContainer();
      generateRecaptcha(newRecaptchaId);
      if (appVerifier) {
        const result = await dispatch(verifyPhone({phoneNumber: formattedPhoneNumber, appVerifier})).unwrap();
        setVerificationId(result.verificationId);
        setIsCodeSent(true);
        setVerificationPending(true);
      }
    } catch (err: any) {
      console.log(err.message)
      setErrorSendCode(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode || !verificationId) {
      setErrorVerifyCode('Please enter the verification code.');
      return;
    }
    setErrorVerifyCode(null);
    setIsLoading(true);
    try {
      await dispatch(verifyPhoneCode({verificationCode, verificationId})).unwrap();
      setPhoneEditable(false);
      setVerificationPending(false);
      setIsCodeSent(false);
      setVerificationCode('');
      alert('Phone number verified successfully!');
      // Reload page
      navigate(0);
    } catch (err: any) {
      console.log(err.message)
      setErrorVerifyCode(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (data: UserData) => {
    const user = Object.entries(data).reduce<Partial<state.User>>((user, [key, value]) => {
      return {...user, [key]: value === undefined ? null : value};
    }, {});
    dispatch(updateProfile(user))
      .unwrap()
      .then(() => {
        success();
        onClose();
      })
      .catch(failure);
    waiting();
  };

  return (
    <Paper sx={{p: 2, my: 2}}>
      <Typography variant="h6" color="secondary">
        Update user profile
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        You can update your profile details.
      </Typography>
      <Form<UserData> onSubmit={handleSubmit} validators={validators} initialData={user}>
        {(state, updateField) => (
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="userFirstname"
                  variant="filled"
                  label="Firstname"
                  value={state.data.userFirstname}
                  onChange={(e) => updateField('userFirstname', e.target.value)}
                  error={Boolean(state.errors.userFirstname)}
                  helperText={state.errors.userFirstname}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="userSurname"
                  variant="filled"
                  label="Surname"
                  value={state.data.userSurname}
                  onChange={(e) => updateField('userSurname', e.target.value)}
                  error={Boolean(state.errors.userSurname)}
                  helperText={state.errors.userSurname}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center">
                  <TextField
                    name="phoneNumber"
                    variant="filled"
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    error={!!errorSendCode}
                    helperText={errorSendCode || ''}
                    fullWidth
                    disabled={!isPhoneEditable}
                    required
                  />
                  <Tooltip title="Edit phone number">
                    <IconButton onClick={handlePhoneEdit} sx={{ml: 1}}>
                      <EditIcon/>
                    </IconButton>
                  </Tooltip>
                </Box>
                {isPhoneEditable && !isVerificationPending && (
                  <WaitButton
                    color="primary"
                    variant="contained"
                    onClick={handleSendVerification}
                    sx={{mt: 1}}
                    wait={isLoading}
                  >
                    {isLoading ? 'Sending...' : 'Send Verification'}
                  </WaitButton>
                )}
                {isVerificationPending && (
                  <>
                    <TextField
                      name="verificationCode"
                      variant="filled"
                      label="Enter Verification Code"
                      value={verificationCode}
                      error={!!errorVerifyCode}
                      helperText={errorVerifyCode || ''}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      fullWidth
                      required
                      sx={{mt: 2}}
                    />
                    <WaitButton
                      color="primary"
                      variant="contained"
                      onClick={handleVerifyCode}
                      sx={{mt: 1}}
                      wait={isLoading}
                    >
                      {isLoading ? 'Verifying...' : 'Verify Code'}
                    </WaitButton>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="childName"
                  variant="filled"
                  label="Child name"
                  value={state.data.childName}
                  onChange={(e) => updateField('childName', e.target.value)}
                  error={Boolean(state.errors.childName)}
                  helperText={state.errors.childName}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  name="childPronouns"
                  variant="filled"
                  label="What are your child's preferred pronouns?"
                  options={preferredPronounsOptions}
                  value={state.data.childPronouns}
                  onChange={(e) => updateField('childPronouns', e.target.value)}
                  error={Boolean(state.errors.childPronouns)}
                  helperText={state.errors.childPronouns}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Stack sx={{mt: 2}} alignItems="center">
              <WaitButton type="submit" color="primary" variant="outlined" wait={updateState.status === 'pending'}>
                Update
              </WaitButton>
            </Stack>
          </Box>
        )}
      </Form>

      <div ref={recaptchaWrapperRef}>
        <div id={recaptchaId}></div>
      </div>
    </Paper>
  );
}
